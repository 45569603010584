import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ToolboxLayout from 'layouts/toolboxLayout';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';
import Contentful2ColumnLayout from 'components/Contentful/Contentful2ColumnLayout';
import useGuidesCards from 'hooks/useGuidesCards';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import toolboxLogos from 'components/Page/Toolbox/data/toolboxLogos';

const GuidesPage = (props) => {
  const dynamicHero = props.data.contentfulDynamicHero;
  const demoForm = props.data.contentful2ColumnLayout;

  const { articlesLogo } = toolboxLogos();

  const cardsBlock = {
    bgColor: 'Transparent',
    cardType: 'Image',
    cards: useGuidesCards(),
  };

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Guides',
        icon: articlesLogo,
        url: '',
        hideInMobile: true,
      },
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={props.data.contentfulSeo}
      layout="toolbox"
      {...props}
    >
      <GuidesPageDynamicHero
        {...dynamicHero}
        images={[]}
        customInlineForm={<HeroSubscribeForm buttonType="primary" theme="light" />}
      />
      <GuidesPageIconCardsSection>
        <ContentfulCardsBlock {...cardsBlock} cols={3} />
      </GuidesPageIconCardsSection>
      <Contentful2ColumnLayout {...demoForm} pageType="normal" />
    </ToolboxLayout>
  );
};

export default GuidesPage;

export const GuidesPageQuery = graphql`
  query GuidesPageQuery {
    contentfulSeo(internalName: { eq: "Guides" }) {
      ...Seo
    }

    contentfulDynamicHero(internalName: { eq: "Dynamic Hero - Guides" }) {
      ...DynamicHero
    }

    contentful2ColumnLayout(contentful_id: { eq: "6eGRiTW0cZlDmpyQNooRwX" }) {
      ...TwoColumnLayout
    }
  }
`;

const GuidesPageDynamicHero = styled(ContentfulDynamicHero)`
  padding-top: 0;

  .section-layout--inner-container {
    padding-top: 160px;
  }

  .hero-subscribe-form-container {
    margin-top: 40px;
    .data-layer-form {
      justify-content: center !important;
    }
  }

  @media (max-width: 575px) {
    .section-layout--inner-container {
      padding-top: 148px;
    }
  }

  @media (max-width: 680px) {
    .hero-copy {
      margin-bottom: 0;
    }
  }
`;

const GuidesPageIconCardsSection = styled.div``;
