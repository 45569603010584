import { graphql, useStaticQuery } from 'gatsby';
import { splashCheck } from 'utils/urlHelper';

const useGuidesCards = () => {
  const { allContentfulGatedContentPage } = useStaticQuery(graphql`
    query {
      allContentfulGatedContentPage(
        filter: { internalPageName: { ne: "Gated Content Test" } }
        sort: { order: DESC, fields: updatedAt }
      ) {
        edges {
          node {
            ...GatedContent
          }
        }
      }
    }
  `);

  const guidesCards = allContentfulGatedContentPage.edges
    .filter((item) => item.node.seo)
    .map((item) => {
      const url = item.node.seo.pageUrl;

      return {
        __typename: item.node.__typename,
        title: item.node.contentOfferTitle,
        description: { description: item.node.excerpt?.excerpt || '' },
        cover: item.node.featuredImage?.image || item.node.assetThumbnail || undefined,
        ctaText: 'Download Now',
        ctaLink: splashCheck(url),
      };
    });

  return guidesCards;
};

export default useGuidesCards;
